/** @jsxImportSource @emotion/react */

import { bottomBarStyle } from "../styles";

export const HeaderBar = (): JSX.Element => {

    return <><header css={bottomBarStyle}>
        <button >filter</button>
    </header>
    </>

};

