import { GalleryImage } from "../types";

export const allImages: GalleryImage[] = [
    { filename: '3-monde-2018/3-monde-1.jpg.webp', title: '3-monde-1', cat: '3 Monde (2018)', range: [2018] },
    { filename: '3-monde-2018/3-monde-10.jpg.webp', title: '3-monde-10', cat: '3 Monde (2018)', range: [2018] },
    { filename: '3-monde-2018/3-monde-2.jpg.webp', title: '3-monde-2', cat: '3 Monde (2018)', range: [2018] },
    { filename: '3-monde-2018/3-monde-3.jpg.webp', title: '3-monde-3', cat: '3 Monde (2018)', range: [2018] },
    { filename: '3-monde-2018/3-monde-4.jpg.webp', title: '3-monde-4', cat: '3 Monde (2018)', range: [2018] },
    { filename: '3-monde-2018/3-monde-5.jpg.webp', title: '3-monde-5', cat: '3 Monde (2018)', range: [2018] },
    { filename: '3-monde-2018/3-monde-6.jpg.webp', title: '3-monde-6', cat: '3 Monde (2018)', range: [2018] },
    { filename: '3-monde-2018/3-monde-7.jpg.webp', title: '3-monde-7', cat: '3 Monde (2018)', range: [2018] },
    { filename: '3-monde-2018/3-monde-8.jpg.webp', title: '3-monde-8', cat: '3 Monde (2018)', range: [2018] },
    { filename: '3-monde-2018/3-monde-9.jpg.webp', title: '3-monde-9', cat: '3 Monde (2018)', range: [2018] },
    { filename: '3-monde-2018/3monde-11.jpg.webp', title: '3monde-11', cat: '3 Monde (2018)', range: [2018] },
    { filename: '3-monde-2018/3monde-12.jpg.webp', title: '3monde-12', cat: '3 Monde (2018)', range: [2018] },
    { filename: '32-denkmaler-1993-1999/aquivokation.jpg.webp', title: 'aquivokation', cat: '32 Denkmäler (1993-1999)', range: [1993,1999] },
    { filename: '32-denkmaler-1993-1999/betonbrut.jpg.webp', title: 'betonbrut', cat: '32 Denkmäler (1993-1999)', range: [1993,1999] },
    { filename: '32-denkmaler-1993-1999/der-exekutant.jpg.webp', title: 'der-exekutant', cat: '32 Denkmäler (1993-1999)', range: [1993,1999] },
    { filename: '32-denkmaler-1993-1999/dismembration.jpg.webp', title: 'dismembration', cat: '32 Denkmäler (1993-1999)', range: [1993,1999] },
    { filename: '32-denkmaler-1993-1999/empiriokritizismus.jpg.webp', title: 'empiriokritizismus', cat: '32 Denkmäler (1993-1999)', range: [1993,1999] },
    { filename: '32-denkmaler-1993-1999/erection-collectivement.jpg.webp', title: 'erection-collectivement', cat: '32 Denkmäler (1993-1999)', range: [1993,1999] },
    { filename: '32-denkmaler-1993-1999/exaudi.jpg.webp', title: 'exaudi', cat: '32 Denkmäler (1993-1999)', range: [1993,1999] },
    { filename: '32-denkmaler-1993-1999/freimaurer.jpg.webp', title: 'freimaurer', cat: '32 Denkmäler (1993-1999)', range: [1993,1999] },
    { filename: '32-denkmaler-1993-1999/gender-studie.jpg.webp', title: 'gender-studie', cat: '32 Denkmäler (1993-1999)', range: [1993,1999] },
    { filename: '32-denkmaler-1993-1999/homofonie.jpg.webp', title: 'homofonie', cat: '32 Denkmäler (1993-1999)', range: [1993,1999] },
    { filename: '32-denkmaler-1993-1999/kadenz.jpg.webp', title: 'kadenz', cat: '32 Denkmäler (1993-1999)', range: [1993,1999] },
    { filename: '32-denkmaler-1993-1999/kassandra.jpg.webp', title: 'kassandra', cat: '32 Denkmäler (1993-1999)', range: [1993,1999] },
    { filename: '32-denkmaler-1993-1999/konszientalistsche-sphynx.jpg.webp', title: 'konszientalistsche-sphynx', cat: '32 Denkmäler (1993-1999)', range: [1993,1999] },
    { filename: '32-denkmaler-1993-1999/kosmodrom.jpg.webp', title: 'kosmodrom', cat: '32 Denkmäler (1993-1999)', range: [1993,1999] },
    { filename: '32-denkmaler-1993-1999/kunstterror.jpg.webp', title: 'kunstterror', cat: '32 Denkmäler (1993-1999)', range: [1993,1999] },
    { filename: '32-denkmaler-1993-1999/la-plus-grande-necessite-du-monde.jpg.webp', title: 'la-plus-grande-necessite-du-monde', cat: '32 Denkmäler (1993-1999)', range: [1993,1999] },
    { filename: '32-denkmaler-1993-1999/mahdi.jpg.webp', title: 'mahdi', cat: '32 Denkmäler (1993-1999)', range: [1993,1999] },
    { filename: '32-denkmaler-1993-1999/majeur.jpg.webp', title: 'majeur', cat: '32 Denkmäler (1993-1999)', range: [1993,1999] },
    { filename: '32-denkmaler-1993-1999/metaphysischer-bettelmonch.jpg.webp', title: 'metaphysischer-bettelmonch', cat: '32 Denkmäler (1993-1999)', range: [1993,1999] },
    { filename: '32-denkmaler-1993-1999/minimalproblem.jpg.webp', title: 'minimalproblem', cat: '32 Denkmäler (1993-1999)', range: [1993,1999] },
    { filename: '32-denkmaler-1993-1999/mithridatist.jpg.webp', title: 'mithridatist', cat: '32 Denkmäler (1993-1999)', range: [1993,1999] },
    { filename: '32-denkmaler-1993-1999/narkomane.jpg.webp', title: 'narkomane', cat: '32 Denkmäler (1993-1999)', range: [1993,1999] },
    { filename: '32-denkmaler-1993-1999/nefas.jpg.webp', title: 'nefas', cat: '32 Denkmäler (1993-1999)', range: [1993,1999] },
    { filename: '32-denkmaler-1993-1999/neoverismus.jpg.webp', title: 'neoverismus', cat: '32 Denkmäler (1993-1999)', range: [1993,1999] },
    { filename: '32-denkmaler-1993-1999/november.jpg.webp', title: 'november', cat: '32 Denkmäler (1993-1999)', range: [1993,1999] },
    { filename: '32-denkmaler-1993-1999/philokalia.jpg.webp', title: 'philokalia', cat: '32 Denkmäler (1993-1999)', range: [1993,1999] },
    { filename: '32-denkmaler-1993-1999/therapie.jpg.webp', title: 'therapie', cat: '32 Denkmäler (1993-1999)', range: [1993,1999] },
    { filename: '32-denkmaler-1993-1999/vertikale-idolatrie.jpg.webp', title: 'vertikale-idolatrie', cat: '32 Denkmäler (1993-1999)', range: [1993,1999] },
    { filename: 'africa-europa-2016/africa-europa-1.jpg.webp', title: 'africa-europa-1', cat: 'Africa-Europa (2016)', range: [2016] },
    { filename: 'africa-europa-2016/africa-europa-2.jpg.webp', title: 'africa-europa-2', cat: 'Africa-Europa (2016)', range: [2016] },
    { filename: 'africa-europa-2016/africa-europa-3.jpg.webp', title: 'africa-europa-3', cat: 'Africa-Europa (2016)', range: [2016] },
    { filename: 'ametat-2020/ametat-1.jpg.webp', title: 'ametat-1', cat: 'Ametat (2020)', range: [2020] },
    { filename: 'ametat-2020/ametat-2.jpg.webp', title: 'ametat-2', cat: 'Ametat (2020)', range: [2020] },
    { filename: 'ametat-2020/ametat-3.jpg.webp', title: 'ametat-3', cat: 'Ametat (2020)', range: [2020] },
    { filename: 'ametat-2020/ametat-4.jpg.webp', title: 'ametat-4', cat: 'Ametat (2020)', range: [2020] },
    { filename: 'ametat-2020/ametat-5.jpg.webp', title: 'ametat-5', cat: 'Ametat (2020)', range: [2020] },
    { filename: 'ametat-2020/ametat-6.jpg.webp', title: 'ametat-6', cat: 'Ametat (2020)', range: [2020] },
    { filename: 'auswahl-1-1995-2008/abschiedsgesang.jpg.webp', title: 'abschiedsgesang', cat: 'Auswahl 1 (1995-2008)', range: [1995,2008] },
    { filename: 'auswahl-1-1995-2008/angst-des-kapitalisten-vor-der-einigkeit-der-arbeiter.jpg.webp', title: 'angst-des-kapitalisten-vor-der-einigkeit-der-arbeiter', cat: 'Auswahl 1 (1995-2008)', range: [1995,2008] },
    { filename: 'auswahl-1-1995-2008/auf-einen-grossen-komiker.jpg.webp', title: 'auf-einen-grossen-komiker', cat: 'Auswahl 1 (1995-2008)', range: [1995,2008] },
    { filename: 'auswahl-1-1995-2008/aus-der-sonne-heraus.jpg.webp', title: 'aus-der-sonne-heraus', cat: 'Auswahl 1 (1995-2008)', range: [1995,2008] },
    { filename: 'auswahl-1-1995-2008/coreolan.jpg.webp', title: 'coreolan', cat: 'Auswahl 1 (1995-2008)', range: [1995,2008] },
    { filename: 'auswahl-1-1995-2008/das-fest-derunterirdischen.jpg.webp', title: 'das-fest-derunterirdischen', cat: 'Auswahl 1 (1995-2008)', range: [1995,2008] },
    { filename: 'auswahl-1-1995-2008/das-llied-vom-kompromiss.jpg.webp', title: 'das-llied-vom-kompromiss', cat: 'Auswahl 1 (1995-2008)', range: [1995,2008] },
    { filename: 'auswahl-1-1995-2008/demirug.jpg.webp', title: 'demirug', cat: 'Auswahl 1 (1995-2008)', range: [1995,2008] },
    { filename: 'auswahl-1-1995-2008/der-kritiker-von-koln.jpg.webp', title: 'der-kritiker-von-koln', cat: 'Auswahl 1 (1995-2008)', range: [1995,2008] },
    { filename: 'auswahl-1-1995-2008/der-tod-als-spassmacher.jpg.webp', title: 'der-tod-als-spassmacher', cat: 'Auswahl 1 (1995-2008)', range: [1995,2008] },
    { filename: 'auswahl-1-1995-2008/der-verutschte-hut.jpg.webp', title: 'der-verutschte-hut', cat: 'Auswahl 1 (1995-2008)', range: [1995,2008] },
    { filename: 'auswahl-1-1995-2008/deutsche-malersage.jpg.webp', title: 'deutsche-malersage', cat: 'Auswahl 1 (1995-2008)', range: [1995,2008] },
    { filename: 'auswahl-1-1995-2008/die-frage-der-macht.jpg.webp', title: 'die-frage-der-macht', cat: 'Auswahl 1 (1995-2008)', range: [1995,2008] },
    { filename: 'auswahl-1-1995-2008/die-gefangenen.jpg.webp', title: 'die-gefangenen', cat: 'Auswahl 1 (1995-2008)', range: [1995,2008] },
    { filename: 'auswahl-1-1995-2008/die-weinenden-hohenzollern.jpg.webp', title: 'die-weinenden-hohenzollern', cat: 'Auswahl 1 (1995-2008)', range: [1995,2008] },
    { filename: 'auswahl-1-1995-2008/diese-welt-isr-eine-null.jpg.webp', title: 'diese-welt-ist-eine-null', cat: 'Auswahl 1 (1995-2008)', range: [1995,2008] },
    { filename: 'auswahl-1-1995-2008/drehorgellied.jpg.webp', title: 'drehorgellied', cat: 'Auswahl 1 (1995-2008)', range: [1995,2008] },
    { filename: 'auswahl-1-1995-2008/drei-minuten-gehor.jpg.webp', title: 'drei-minuten-gehor', cat: 'Auswahl 1 (1995-2008)', range: [1995,2008] },
    { filename: 'auswahl-1-1995-2008/du-bist-maler.jpg.webp', title: 'du-bist-maler', cat: 'Auswahl 1 (1995-2008)', range: [1995,2008] },
    { filename: 'auswahl-1-1995-2008/ein-altes-lied.jpg.webp', title: 'ein-altes-lied', cat: 'Auswahl 1 (1995-2008)', range: [1995,2008] },
    { filename: 'auswahl-1-1995-2008/erinnerung.jpg.webp', title: 'erinnerung', cat: 'Auswahl 1 (1995-2008)', range: [1995,2008] },
    { filename: 'auswahl-1-1995-2008/erleuchtung-in-der-wuste.jpg.webp', title: 'erleuchtung-in-der-wuste', cat: 'Auswahl 1 (1995-2008)', range: [1995,2008] },
    { filename: 'auswahl-1-1995-2008/fruhling-im-hochgebirge.jpg.webp', title: 'fruhling-im-hochgebirge', cat: 'Auswahl 1 (1995-2008)', range: [1995,2008] },
    { filename: 'auswahl-1-1995-2008/fruhling.jpg.webp', title: 'fruhling', cat: 'Auswahl 1 (1995-2008)', range: [1995,2008] },
    { filename: 'auswahl-1-1995-2008/gebet-fur-die-gefangenen.jpg.webp', title: 'gebet-fur-die-gefangenen', cat: 'Auswahl 1 (1995-2008)', range: [1995,2008] },
    { filename: 'auswahl-1-1995-2008/gedanken-und-erinnerungen.jpg.webp', title: 'gedanken-und-erinnerungen', cat: 'Auswahl 1 (1995-2008)', range: [1995,2008] },
    { filename: 'auswahl-1-1995-2008/harden.jpg.webp', title: 'harden', cat: 'Auswahl 1 (1995-2008)', range: [1995,2008] },
    { filename: 'auswahl-1-1995-2008/junger-maler.jpg.webp', title: 'junger-maler', cat: 'Auswahl 1 (1995-2008)', range: [1995,2008] },
    { filename: 'auswahl-1-1995-2008/kleine-asiatische-gotter.jpg.webp', title: 'kleine-asiatische-gotter', cat: 'Auswahl 1 (1995-2008)', range: [1995,2008] },
    { filename: 'auswahl-1-1995-2008/kleine-fingerubung.jpg.webp', title: 'kleine-fingerubung', cat: 'Auswahl 1 (1995-2008)', range: [1995,2008] },
    { filename: 'auswahl-1-1995-2008/krieg-dem-krieg.jpg.webp', title: 'krieg-dem-krieg', cat: 'Auswahl 1 (1995-2008)', range: [1995,2008] },
    { filename: 'auswahl-1-1995-2008/kriegsteilnehmer.jpg.webp', title: 'kriegsteilnehmer', cat: 'Auswahl 1 (1995-2008)', range: [1995,2008] },
    { filename: 'auswahl-1-1995-2008/land-der-jugend.jpg.webp', title: 'land-der-jugend', cat: 'Auswahl 1 (1995-2008)', range: [1995,2008] },
    { filename: 'auswahl-1-1995-2008/lieb-zur-luge.jpg.webp', title: 'lieb-zur-luge', cat: 'Auswahl 1 (1995-2008)', range: [1995,2008] },
    { filename: 'auswahl-1-1995-2008/limbisches-system.jpg.webp', title: 'limbisches-system', cat: 'Auswahl 1 (1995-2008)', range: [1995,2008] },
    { filename: 'auswahl-1-1995-2008/nacht.jpg.webp', title: 'nacht', cat: 'Auswahl 1 (1995-2008)', range: [1995,2008] },
    { filename: 'auswahl-1-1995-2008/nitschewo.jpg.webp', title: 'nitschewo', cat: 'Auswahl 1 (1995-2008)', range: [1995,2008] },
    { filename: 'auswahl-1-1995-2008/paradoxisten.jpg.webp', title: 'paradoxisten', cat: 'Auswahl 1 (1995-2008)', range: [1995,2008] },
    { filename: 'auswahl-1-1995-2008/paris.jpg.webp', title: 'paris', cat: 'Auswahl 1 (1995-2008)', range: [1995,2008] },
    { filename: 'auswahl-1-1995-2008/pelasgischer-schopfungsmythos.jpg.webp', title: 'pelasgischer-schopfungsmythos', cat: 'Auswahl 1 (1995-2008)', range: [1995,2008] },
    { filename: 'auswahl-1-1995-2008/rausgewunken-und-erschossen.jpg.webp', title: 'rausgewunken-und-erschossen', cat: 'Auswahl 1 (1995-2008)', range: [1995,2008] },
    { filename: 'auswahl-1-1995-2008/religionsunterricht.jpg.webp', title: 'religionsunterricht', cat: 'Auswahl 1 (1995-2008)', range: [1995,2008] },
    { filename: 'auswahl-1-1995-2008/revolutions-ruckblick.jpg.webp', title: 'revolutions-ruckblick', cat: 'Auswahl 1 (1995-2008)', range: [1995,2008] },
    { filename: 'auswahl-1-1995-2008/rheinundine.jpg.webp', title: 'rheinundine', cat: 'Auswahl 1 (1995-2008)', range: [1995,2008] },
    { filename: 'auswahl-1-1995-2008/rom.jpg.webp', title: 'rom', cat: 'Auswahl 1 (1995-2008)', range: [1995,2008] },
    { filename: 'auswahl-1-1995-2008/rota.jpg.webp', title: 'rota', cat: 'Auswahl 1 (1995-2008)', range: [1995,2008] },
    { filename: 'auswahl-1-1995-2008/rote-melodie.jpg.webp', title: 'rote-melodie', cat: 'Auswahl 1 (1995-2008)', range: [1995,2008] },
    { filename: 'auswahl-1-1995-2008/ruckkehr-zur-natur.jpg.webp', title: 'ruckkehr-zur-natur', cat: 'Auswahl 1 (1995-2008)', range: [1995,2008] },
    { filename: 'auswahl-1-1995-2008/schicksalslied.jpg.webp', title: 'schicksalslied', cat: 'Auswahl 1 (1995-2008)', range: [1995,2008] },
    { filename: 'auswahl-1-1995-2008/schwarze-locher.jpg.webp', title: 'schwarze-locher', cat: 'Auswahl 1 (1995-2008)', range: [1995,2008] },
    { filename: 'auswahl-1-1995-2008/sekundartugened.jpg.webp', title: 'sekundartugened', cat: 'Auswahl 1 (1995-2008)', range: [1995,2008] },
    { filename: 'auswahl-1-1995-2008/sexuelle-aufklaeung.jpg.webp', title: 'sexuelle-aufklaeung', cat: 'Auswahl 1 (1995-2008)', range: [1995,2008] },
    { filename: 'auswahl-1-1995-2008/sonett-fur-blinde.jpg.webp', title: 'sonett-fur-blinde', cat: 'Auswahl 1 (1995-2008)', range: [1995,2008] },
    { filename: 'auswahl-1-1995-2008/spaniache-krankheit.jpg.webp', title: 'spaniache-krankheit', cat: 'Auswahl 1 (1995-2008)', range: [1995,2008] },
    { filename: 'auswahl-1-1995-2008/spazierganger-in-berlin.jpg.webp', title: 'spazierganger-in-berlin', cat: 'Auswahl 1 (1995-2008)', range: [1995,2008] },
    { filename: 'auswahl-1-1995-2008/spieler.jpg.webp', title: 'spieler', cat: 'Auswahl 1 (1995-2008)', range: [1995,2008] },
    { filename: 'auswahl-1-1995-2008/trunkenes-lied.jpg.webp', title: 'trunkenes-lied', cat: 'Auswahl 1 (1995-2008)', range: [1995,2008] },
    { filename: 'auswahl-1-1995-2008/tunesischer-versager.jpg.webp', title: 'tunesischer-versager', cat: 'Auswahl 1 (1995-2008)', range: [1995,2008] },
    { filename: 'auswahl-1-1995-2008/unser-militar.jpg.webp', title: 'unser-militar', cat: 'Auswahl 1 (1995-2008)', range: [1995,2008] },
    { filename: 'auswahl-1-1995-2008/unser-taglich-brot.jpg.webp', title: 'unser-taglich-brot', cat: 'Auswahl 1 (1995-2008)', range: [1995,2008] },
    { filename: 'auswahl-1-1995-2008/verfehlte-nacht.jpg.webp', title: 'verfehlte-nacht', cat: 'Auswahl 1 (1995-2008)', range: [1995,2008] },
    { filename: 'auswahl-1-1995-2008/vergelts-gott.jpg.webp', title: 'vergelts-gott', cat: 'Auswahl 1 (1995-2008)', range: [1995,2008] },
    { filename: 'auswahl-1-1995-2008/wenn-die-muse-kut.jpg.webp', title: 'wenn-die-muse-kut', cat: 'Auswahl 1 (1995-2008)', range: [1995,2008] },
    { filename: 'auswahl-1-1995-2008/zeit-du-bist-entehrt.jpg.webp', title: 'zeit-du-bist-entehrt', cat: 'Auswahl 1 (1995-2008)', range: [1995,2008] },
    { filename: 'auswahl-1-1995-2008/zerebrale-dominanz.jpg.webp', title: 'zerebrale-dominanz', cat: 'Auswahl 1 (1995-2008)', range: [1995,2008] },
    { filename: 'auswahl-2-2008-2013/altes-lied.jpg.webp', title: 'altes-lied', cat: 'Auswahl 2 (2008-2013)', range: [2008,2013] },
    { filename: 'auswahl-2-2008-2013/aramasramas.jpg.webp', title: 'aramasramas', cat: 'Auswahl 2 (2008-2013)', range: [2008,2013] },
    { filename: 'auswahl-2-2008-2013/assassins.jpg.webp', title: 'assassins', cat: 'Auswahl 2 (2008-2013)', range: [2008,2013] },
    { filename: 'auswahl-2-2008-2013/atakor.jpg.webp', title: 'atakor', cat: 'Auswahl 2 (2008-2013)', range: [2008,2013] },
    { filename: 'auswahl-2-2008-2013/berlin-gibt-auf.jpg.webp', title: 'berlin-gibt-auf', cat: 'Auswahl 2 (2008-2013)', range: [2008,2013] },
    { filename: 'auswahl-2-2008-2013/denkmal-fur-den-heiligen-donald.jpg.webp', title: 'denkmal-fur-den-heiligen-donald', cat: 'Auswahl 2 (2008-2013)', range: [2008,2013] },
    { filename: 'auswahl-2-2008-2013/der-dichter-bei-der-arbeit.jpg.webp', title: 'der-dichter-bei-der-arbeit', cat: 'Auswahl 2 (2008-2013)', range: [2008,2013] },
    { filename: 'auswahl-2-2008-2013/der-kriegslieferant.jpg.webp', title: 'der-kriegslieferant', cat: 'Auswahl 2 (2008-2013)', range: [2008,2013] },
    { filename: 'auswahl-2-2008-2013/der-kunstler-bei-der-arbeit.jpg.webp', title: 'der-kunstler-bei-der-arbeit', cat: 'Auswahl 2 (2008-2013)', range: [2008,2013] },
    { filename: 'auswahl-2-2008-2013/die-philosophen-und-ihre-kerngedanken.jpg.webp', title: 'die-philosophen-und-ihre-kerngedanken', cat: 'Auswahl 2 (2008-2013)', range: [2008,2013] },
    { filename: 'auswahl-2-2008-2013/gedanken-eines-arbeiters-an-einer-kreissage.jpg.webp', title: 'gedanken-eines-arbeiters-an-einer-kreissage', cat: 'Auswahl 2 (2008-2013)', range: [2008,2013] },
    { filename: 'auswahl-2-2008-2013/gesprach-der-gotzen.jpg.webp', title: 'gesprach-der-gotzen', cat: 'Auswahl 2 (2008-2013)', range: [2008,2013] },
    { filename: 'auswahl-2-2008-2013/holofernes-motiv.jpg.webp', title: 'holofernes-motiv', cat: 'Auswahl 2 (2008-2013)', range: [2008,2013] },
    { filename: 'auswahl-2-2008-2013/ilaman.jpg.webp', title: 'ilaman', cat: 'Auswahl 2 (2008-2013)', range: [2008,2013] },
    { filename: 'auswahl-2-2008-2013/ils-ont-bu.jpg.webp', title: 'ils-ont-bu', cat: 'Auswahl 2 (2008-2013)', range: [2008,2013] },
    { filename: 'auswahl-2-2008-2013/ivrogne.jpg.webp', title: 'ivrogne', cat: 'Auswahl 2 (2008-2013)', range: [2008,2013] },
    { filename: 'auswahl-2-2008-2013/j-aime-avoir-peure.jpg.webp', title: 'j-aime-avoir-peure', cat: 'Auswahl 2 (2008-2013)', range: [2008,2013] },
    { filename: 'auswahl-2-2008-2013/la-belle-va-au-chateau.jpg.webp', title: 'la-belle-va-au-chateau', cat: 'Auswahl 2 (2008-2013)', range: [2008,2013] },
    { filename: 'auswahl-2-2008-2013/le-domaine-de-la-bete.jpg.webp', title: 'le-domaine-de-la-bete', cat: 'Auswahl 2 (2008-2013)', range: [2008,2013] },
    { filename: 'auswahl-2-2008-2013/le-magnifique-apparait.jpg.webp', title: 'le-magnifique-apparait', cat: 'Auswahl 2 (2008-2013)', range: [2008,2013] },
    { filename: 'auswahl-2-2008-2013/nahere-bekanntschaft.jpg.webp', title: 'nahere-bekanntschaft', cat: 'Auswahl 2 (2008-2013)', range: [2008,2013] },
    { filename: 'auswahl-2-2008-2013/oh-frau.jpg.webp', title: 'oh-frau', cat: 'Auswahl 2 (2008-2013)', range: [2008,2013] },
    { filename: 'auswahl-2-2008-2013/psychoanalyse.jpg.webp', title: 'psychoanalyse', cat: 'Auswahl 2 (2008-2013)', range: [2008,2013] },
    { filename: 'auswahl-2-2008-2013/rota.jpg.webp', title: 'rota', cat: 'Auswahl 2 (2008-2013)', range: [2008,2013] },
    { filename: 'auswahl-2-2008-2013/ti-n-herkan.jpg.webp', title: 'ti-n-herkan', cat: 'Auswahl 2 (2008-2013)', range: [2008,2013] },
    { filename: 'auswahl-2-2008-2013/ti-n-tamatt.jpg.webp', title: 'ti-n-tamatt', cat: 'Auswahl 2 (2008-2013)', range: [2008,2013] },
    { filename: 'auswahl-2-2008-2013/ti-n-traz.jpg.webp', title: 'ti-n-traz', cat: 'Auswahl 2 (2008-2013)', range: [2008,2013] },
    { filename: 'auswahl-2-2008-2013/tricheur.jpg.webp', title: 'tricheur', cat: 'Auswahl 2 (2008-2013)', range: [2008,2013] },
    { filename: 'auswahl-2-2008-2013/twoqqin.jpg.webp', title: 'twoqqin', cat: 'Auswahl 2 (2008-2013)', range: [2008,2013] },
    { filename: 'auswahl-2-2008-2013/und-sonntags-tu-ich-dichten.jpg.webp', title: 'und-sonntags-tu-ich-dichten', cat: 'Auswahl 2 (2008-2013)', range: [2008,2013] },
    { filename: 'auswahl-2-2008-2013/utul.jpg.webp', title: 'utul', cat: 'Auswahl 2 (2008-2013)', range: [2008,2013] },
    { filename: 'auswahl-2-2008-2013/va-ou-je-vais.jpg.webp', title: 'va-ou-je-vais', cat: 'Auswahl 2 (2008-2013)', range: [2008,2013] },
    { filename: 'auswahl-2-2008-2013/zwei-malerfursten-besuchen-donald-judd-um-ihn-auf-zu-lockern.jpg.webp', title: 'zwei-malerfursten-besuchen-donald-judd-um-ihn-auf-zu-lockern', cat: 'Auswahl 2 (2008-2013)', range: [2008,2013] },
    { filename: 'auswahl-2-2008-2013/zwei-malerfursten.jpg.webp', title: 'zwei-malerfursten', cat: 'Auswahl 2 (2008-2013)', range: [2008,2013] },
    { filename: 'auswahl-3-2008-2012/ahaggar.jpg.webp', title: 'ahaggar', cat: 'Auswahl 3 (2008-2012)', range: [2008,2012] },
    { filename: 'auswahl-3-2008-2012/altes-lied.jpg.webp', title: 'altes-lied', cat: 'Auswahl 3 (2008-2012)', range: [2008,2012] },
    { filename: 'auswahl-3-2008-2012/aramasramas.jpg.webp', title: 'aramasramas', cat: 'Auswahl 3 (2008-2012)', range: [2008,2012] },
    { filename: 'auswahl-3-2008-2012/assassins.jpg.webp', title: 'assassins', cat: 'Auswahl 3 (2008-2012)', range: [2008,2012] },
    { filename: 'auswahl-3-2008-2012/atakor.jpg.webp', title: 'atakor', cat: 'Auswahl 3 (2008-2012)', range: [2008,2012] },
    { filename: 'auswahl-3-2008-2012/azru.jpg.webp', title: 'azru', cat: 'Auswahl 3 (2008-2012)', range: [2008,2012] },
    { filename: 'auswahl-3-2008-2012/denkmal-fur-den-heiligen-donald.jpg.webp', title: 'denkmal-fur-den-heiligen-donald', cat: 'Auswahl 3 (2008-2012)', range: [2008,2012] },
    { filename: 'auswahl-3-2008-2012/der-kunstler-bei-der-arbeit.jpg.webp', title: 'der-kunstler-bei-der-arbeit', cat: 'Auswahl 3 (2008-2012)', range: [2008,2012] },
    { filename: 'auswahl-3-2008-2012/dichtkunst.jpg.webp', title: 'dichtkunst', cat: 'Auswahl 3 (2008-2012)', range: [2008,2012] },
    { filename: 'auswahl-3-2008-2012/die-philosophen-und-ihre-kerngedanken.jpg.webp', title: 'die-philosophen-und-ihre-kerngedanken', cat: 'Auswahl 3 (2008-2012)', range: [2008,2012] },
    { filename: 'auswahl-3-2008-2012/gedanken_eines_arbeiters_an_einer_kreissage.jpg.webp', title: 'gedanken_eines_arbeiters_an_einer_kreissage', cat: 'Auswahl 3 (2008-2012)', range: [2008,2012] },
    { filename: 'auswahl-3-2008-2012/gesprach-der-gotzen.jpg.webp', title: 'gesprach-der-gotzen', cat: 'Auswahl 3 (2008-2012)', range: [2008,2012] },
    { filename: 'auswahl-3-2008-2012/holofernes-motiv.jpg.webp', title: 'holofernes-motiv', cat: 'Auswahl 3 (2008-2012)', range: [2008,2012] },
    { filename: 'auswahl-3-2008-2012/ilaman.jpg.webp', title: 'ilaman', cat: 'Auswahl 3 (2008-2012)', range: [2008,2012] },
    { filename: 'auswahl-3-2008-2012/ils-ont-bu.jpg.webp', title: 'ils-ont-bu', cat: 'Auswahl 3 (2008-2012)', range: [2008,2012] },
    { filename: 'auswahl-3-2008-2012/ivrogne.jpg.webp', title: 'ivrogne', cat: 'Auswahl 3 (2008-2012)', range: [2008,2012] },
    { filename: 'auswahl-3-2008-2012/j-aime-avoir-peure.jpg.webp', title: 'j-aime-avoir-peure', cat: 'Auswahl 3 (2008-2012)', range: [2008,2012] },
    { filename: 'auswahl-3-2008-2012/la-belle-va-au-chateau.jpg.webp', title: 'la-belle-va-au-chateau', cat: 'Auswahl 3 (2008-2012)', range: [2008,2012] },
    { filename: 'auswahl-3-2008-2012/le-domaine-de-la-bete.jpg.webp', title: 'le-domaine-de-la-bete', cat: 'Auswahl 3 (2008-2012)', range: [2008,2012] },
    { filename: 'auswahl-3-2008-2012/le-magnifique-apparait.jpg.webp', title: 'le-magnifique-apparait', cat: 'Auswahl 3 (2008-2012)', range: [2008,2012] },
    { filename: 'auswahl-3-2008-2012/mezzuliet.jpg.webp', title: 'mezzuliet', cat: 'Auswahl 3 (2008-2012)', range: [2008,2012] },
    { filename: 'auswahl-3-2008-2012/nahere-bekanntschaft.jpg.webp', title: 'nahere-bekanntschaft', cat: 'Auswahl 3 (2008-2012)', range: [2008,2012] },
    { filename: 'auswahl-3-2008-2012/psychoanalyse.jpg.webp', title: 'psychoanalyse', cat: 'Auswahl 3 (2008-2012)', range: [2008,2012] },
    { filename: 'auswahl-3-2008-2012/rota.jpg.webp', title: 'rota', cat: 'Auswahl 3 (2008-2012)', range: [2008,2012] },
    { filename: 'auswahl-3-2008-2012/serkut.jpg.webp', title: 'serkut', cat: 'Auswahl 3 (2008-2012)', range: [2008,2012] },
    { filename: 'auswahl-3-2008-2012/ti-n-herkan.jpg.webp', title: 'ti-n-herkan', cat: 'Auswahl 3 (2008-2012)', range: [2008,2012] },
    { filename: 'auswahl-3-2008-2012/ti-n-tamatt.jpg.webp', title: 'ti-n-tamatt', cat: 'Auswahl 3 (2008-2012)', range: [2008,2012] },
    { filename: 'auswahl-3-2008-2012/ti-n-traz.jpg.webp', title: 'ti-n-traz', cat: 'Auswahl 3 (2008-2012)', range: [2008,2012] },
    { filename: 'auswahl-3-2008-2012/torpedo-los.jpg.webp', title: 'torpedo-los', cat: 'Auswahl 3 (2008-2012)', range: [2008,2012] },
    { filename: 'auswahl-3-2008-2012/tricheur.jpg.webp', title: 'tricheur', cat: 'Auswahl 3 (2008-2012)', range: [2008,2012] },
    { filename: 'auswahl-3-2008-2012/twoqqin.jpg.webp', title: 'twoqqin', cat: 'Auswahl 3 (2008-2012)', range: [2008,2012] },
    { filename: 'auswahl-3-2008-2012/utul.jpg.webp', title: 'utul', cat: 'Auswahl 3 (2008-2012)', range: [2008,2012] },
    { filename: 'auswahl-3-2008-2012/va-ou-je-vais.jpg.webp', title: 'va-ou-je-vais', cat: 'Auswahl 3 (2008-2012)', range: [2008,2012] },
    { filename: 'auswahl-3-2008-2012/zwei-malerfursten.jpg.webp', title: 'zwei-malerfursten', cat: 'Auswahl 3 (2008-2012)', range: [2008,2012] },
    { filename: 'baudelaire-2009-2012/abel-und-kain.jpg.webp', title: 'abel-und-kain', cat: 'Baudelaire (2009-2012)', range: [2009,2012] },
    { filename: 'baudelaire-2009-2012/abenddammerung.jpg.webp', title: 'abenddammerung', cat: 'Baudelaire (2009-2012)', range: [2009,2012] },
    { filename: 'baudelaire-2009-2012/alchimie-des-schmerzes.jpg.webp', title: 'alchimie-des-schmerzes', cat: 'Baudelaire (2009-2012)', range: [2009,2012] },
    { filename: 'baudelaire-2009-2012/allegorie.jpg.webp', title: 'allegorie', cat: 'Baudelaire (2009-2012)', range: [2009,2012] },
    { filename: 'baudelaire-2009-2012/an-ein-rothaariges-bettelmadchen.jpg.webp', title: 'an-ein-rothaariges-bettelmadchen', cat: 'Baudelaire (2009-2012)', range: [2009,2012] },
    { filename: 'baudelaire-2009-2012/an-eine-die-voruberging-2.jpg.webp', title: 'an-eine-die-voruberging-2', cat: 'Baudelaire (2009-2012)', range: [2009,2012] },
    { filename: 'baudelaire-2009-2012/an-eine-madonna.jpg.webp', title: 'an-eine-madonna', cat: 'Baudelaire (2009-2012)', range: [2009,2012] },
    { filename: 'baudelaire-2009-2012/besessenheit.jpg.webp', title: 'besessenheit', cat: 'Baudelaire (2009-2012)', range: [2009,2012] },
    { filename: 'baudelaire-2009-2012/das-bildnis.jpg.webp', title: 'das-bildnis', cat: 'Baudelaire (2009-2012)', range: [2009,2012] },
    { filename: 'baudelaire-2009-2012/das-fruhere-leben.jpg.webp', title: 'das-fruhere-leben', cat: 'Baudelaire (2009-2012)', range: [2009,2012] },
    { filename: 'baudelaire-2009-2012/das-gespenst.jpg.webp', title: 'das-gespenst', cat: 'Baudelaire (2009-2012)', range: [2009,2012] },
    { filename: 'baudelaire-2009-2012/das-haar.jpg.webp', title: 'das-haar', cat: 'Baudelaire (2009-2012)', range: [2009,2012] },
    { filename: 'baudelaire-2009-2012/der-heautontimoroumenos.jpg.webp', title: 'der-heautontimoroumenos', cat: 'Baudelaire (2009-2012)', range: [2009,2012] },
    { filename: 'baudelaire-2009-2012/der-schlechte-monch.jpg.webp', title: 'der-schlechte-monch', cat: 'Baudelaire (2009-2012)', range: [2009,2012] },
    { filename: 'baudelaire-2009-2012/der-schwan.jpg.webp', title: 'der-schwan', cat: 'Baudelaire (2009-2012)', range: [2009,2012] },
    { filename: 'baudelaire-2009-2012/der-tod-der-armen.jpg.webp', title: 'der-tod-der-armen', cat: 'Baudelaire (2009-2012)', range: [2009,2012] },
    { filename: 'baudelaire-2009-2012/der-tod-der-kunstler.jpg.webp', title: 'der-tod-der-kunstler', cat: 'Baudelaire (2009-2012)', range: [2009,2012] },
    { filename: 'baudelaire-2009-2012/der-tod-der-liebenden.jpg.webp', title: 'der-tod-der-liebenden', cat: 'Baudelaire (2009-2012)', range: [2009,2012] },
    { filename: 'baudelaire-2009-2012/der-traum-eines-neugierigen.jpg.webp', title: 'der-traum-eines-neugierigen', cat: 'Baudelaire (2009-2012)', range: [2009,2012] },
    { filename: 'baudelaire-2009-2012/der-wein-der-liebenden.jpg.webp', title: 'der-wein-der-liebenden', cat: 'Baudelaire (2009-2012)', range: [2009,2012] },
    { filename: 'baudelaire-2009-2012/der-wein-des-einsamen.jpg.webp', title: 'der-wein-des-einsamen', cat: 'Baudelaire (2009-2012)', range: [2009,2012] },
    { filename: 'baudelaire-2009-2012/der-wein-des-lumpensammlers.jpg.webp', title: 'der-wein-des-lumpensammlers', cat: 'Baudelaire (2009-2012)', range: [2009,2012] },
    { filename: 'baudelaire-2009-2012/der-wein-des-morders.jpg.webp', title: 'der-wein-des-morders', cat: 'Baudelaire (2009-2012)', range: [2009,2012] },
    { filename: 'baudelaire-2009-2012/die-blinden.jpg.webp', title: 'die-blinden', cat: 'Baudelaire (2009-2012)', range: [2009,2012] },
    { filename: 'baudelaire-2009-2012/die-blutfontane.jpg.webp', title: 'die-blutfontane', cat: 'Baudelaire (2009-2012)', range: [2009,2012] },
    { filename: 'baudelaire-2009-2012/die-kaufliche-muse.jpg.webp', title: 'die-kaufliche-muse', cat: 'Baudelaire (2009-2012)', range: [2009,2012] },
    { filename: 'baudelaire-2009-2012/die-kranke-muse.jpg.webp', title: 'die-kranke-muse', cat: 'Baudelaire (2009-2012)', range: [2009,2012] },
    { filename: 'baudelaire-2009-2012/die-satanslitaneien.jpg.webp', title: 'die-satanslitaneien', cat: 'Baudelaire (2009-2012)', range: [2009,2012] },
    { filename: 'baudelaire-2009-2012/die-schonheit.jpg.webp', title: 'die-schonheit', cat: 'Baudelaire (2009-2012)', range: [2009,2012] },
    { filename: 'baudelaire-2009-2012/die-seele-des-weines.jpg.webp', title: 'die-seele-des-weines', cat: 'Baudelaire (2009-2012)', range: [2009,2012] },
    { filename: 'baudelaire-2009-2012/die-tanzende-schlange.jpg.webp', title: 'die-tanzende-schlange', cat: 'Baudelaire (2009-2012)', range: [2009,2012] },
    { filename: 'baudelaire-2009-2012/die-verleugnung-des-heiligen-petrus.jpg.webp', title: 'die-verleugnung-des-heiligen-petrus', cat: 'Baudelaire (2009-2012)', range: [2009,2012] },
    { filename: 'baudelaire-2009-2012/du-nahmst-die-ganze-welt-ins-bett-zum-zeitvertreib.jpg.webp', title: 'du-nahmst-die-ganze-welt-ins-bett-zum-zeitvertreib', cat: 'Baudelaire (2009-2012)', range: [2009,2012] },
    { filename: 'baudelaire-2009-2012/eine-erscheinung.jpg.webp', title: 'eine-erscheinung', cat: 'Baudelaire (2009-2012)', range: [2009,2012] },
    { filename: 'baudelaire-2009-2012/eine-martyrerin.jpg.webp', title: 'eine-martyrerin', cat: 'Baudelaire (2009-2012)', range: [2009,2012] },
    { filename: 'baudelaire-2009-2012/eine-reise-nach-kythera.jpg.webp', title: 'eine-reise-nach-kythera', cat: 'Baudelaire (2009-2012)', range: [2009,2012] },
    { filename: 'baudelaire-2009-2012/einladung-zur-reise.jpg.webp', title: 'einladung-zur-reise', cat: 'Baudelaire (2009-2012)', range: [2009,2012] },
    { filename: 'baudelaire-2009-2012/herbstsonett.jpg.webp', title: 'herbstsonett', cat: 'Baudelaire (2009-2012)', range: [2009,2012] },
    { filename: 'baudelaire-2009-2012/hymne-an-die-schonheit.jpg.webp', title: 'hymne-an-die-schonheit', cat: 'Baudelaire (2009-2012)', range: [2009,2012] },
    { filename: 'baudelaire-2009-2012/lob-auf-meine-franziska.jpg.webp', title: 'lob-auf-meine-franziska', cat: 'Baudelaire (2009-2012)', range: [2009,2012] },
    { filename: 'baudelaire-2009-2012/lunas-schwermut.jpg.webp', title: 'lunas-schwermut', cat: 'Baudelaire (2009-2012)', range: [2009,2012] },
    { filename: 'baudelaire-2009-2012/moesta-et-errabunda.jpg.webp', title: 'moesta-et-errabunda', cat: 'Baudelaire (2009-2012)', range: [2009,2012] },
    { filename: 'baudelaire-2009-2012/morgendammerung.jpg.webp', title: 'morgendammerung', cat: 'Baudelaire (2009-2012)', range: [2009,2012] },
    { filename: 'baudelaire-2009-2012/nebel-und-regen.jpg.webp', title: 'nebel-und-regen', cat: 'Baudelaire (2009-2012)', range: [2009,2012] },
    { filename: 'baudelaire-2009-2012/neigung-zum-nichts-2.jpg.webp', title: 'neigung-zum-nichts-2', cat: 'Baudelaire (2009-2012)', range: [2009,2012] },
    { filename: 'baudelaire-2009-2012/pariser-traum.jpg.webp', title: 'pariser-traum', cat: 'Baudelaire (2009-2012)', range: [2009,2012] },
    { filename: 'baudelaire-2009-2012/sed-non-satiata.jpg.webp', title: 'sed-non-satiata', cat: 'Baudelaire (2009-2012)', range: [2009,2012] },
    { filename: 'baudelaire-2009-2012/semper-aedem.jpg.webp', title: 'semper-aedem', cat: 'Baudelaire (2009-2012)', range: [2009,2012] },
    { filename: 'baudelaire-2009-2012/sisina.jpg.webp', title: 'sisina', cat: 'Baudelaire (2009-2012)', range: [2009,2012] },
    { filename: 'baudelaire-2009-2012/spleen-der-regenmond-erbost-auf-die-ganze-sadt.jpg.webp', title: 'spleen-der-regenmond-erbost-auf-die-ganze-sadt', cat: 'Baudelaire (2009-2012)', range: [2009,2012] },
    { filename: 'baudelaire-2009-2012/spleen-ich-bin-dem-konig-eines-regenlandes-gleich.jpg.webp', title: 'spleen-ich-bin-dem-konig-eines-regenlandes-gleich', cat: 'Baudelaire (2009-2012)', range: [2009,2012] },
    { filename: 'baudelaire-2009-2012/spleen-ich-habe-mehr-erinnerung-als-zahlt-ich-tausend-jahre.jpg.webp', title: 'spleen-ich-habe-mehr-erinnerung-als-zahlt-ich-tausend-jahre', cat: 'Baudelaire (2009-2012)', range: [2009,2012] },
    { filename: 'baudelaire-2009-2012/spleen-wenn-der-tiefe-himmel-wie-ein-deckel-lastet.jpg.webp', title: 'spleen-wenn-der-tiefe-himmel-wie-ein-deckel-lastet', cat: 'Baudelaire (2009-2012)', range: [2009,2012] },
    { filename: 'baudelaire-2009-2012/sympathetisches-grauen.jpg.webp', title: 'sympathetisches-grauen', cat: 'Baudelaire (2009-2012)', range: [2009,2012] },
    { filename: 'baudelaire-2009-2012/totentanz.jpg.webp', title: 'totentanz', cat: 'Baudelaire (2009-2012)', range: [2009,2012] },
    { filename: 'baudelaire-2009-2012/unwiederbringlichkeit.jpg.webp', title: 'unwiederbringlichkeit', cat: 'Baudelaire (2009-2012)', range: [2009,2012] },
    { filename: 'baudelaire-2009-2012/zuchtigung-der-hoffart.jpg.webp', title: 'zuchtigung-der-hoffart', cat: 'Baudelaire (2009-2012)', range: [2009,2012] },
    { filename: 'berlin-gibt-auf-2015/berlin-gibt-auf-1.jpg.webp', title: 'berlin-gibt-auf-1', cat: 'Berlin gibt auf (2015)', range: [2015] },
    { filename: 'berlin-gibt-auf-2015/berlin-gibt-auf-2.jpg.webp', title: 'berlin-gibt-auf-2', cat: 'Berlin gibt auf (2015)', range: [2015] },
    { filename: 'berlin-gibt-auf-2015/berlin-gibt-auf-3.jpg.webp', title: 'berlin-gibt-auf-3', cat: 'Berlin gibt auf (2015)', range: [2015] },
    { filename: 'bilder-1995-2008/achte-sekunde.jpg.webp', title: 'achte-sekunde', cat: 'Bilder (1995-2008)', range: [1995,2008] },
    { filename: 'bilder-1995-2008/aus-der-sonne-heraus.jpg.webp', title: 'aus-der-sonne-heraus', cat: 'Bilder (1995-2008)', range: [1995,2008] },
    { filename: 'bilder-1995-2008/coreolan.jpg.webp', title: 'coreolan', cat: 'Bilder (1995-2008)', range: [1995,2008] },
    { filename: 'bilder-1995-2008/das-fest-derunterirdischen.jpg.webp', title: 'das-fest-derunterirdischen', cat: 'Bilder (1995-2008)', range: [1995,2008] },
    { filename: 'bilder-1995-2008/das-llied-vom-kompromiss.jpg.webp', title: 'das-llied-vom-kompromiss', cat: 'Bilder (1995-2008)', range: [1995,2008] },
    { filename: 'bilder-1995-2008/der-kritiker-von-koln.jpg.webp', title: 'der-kritiker-von-koln', cat: 'Bilder (1995-2008)', range: [1995,2008] },
    { filename: 'bilder-1995-2008/der-kunstfreund.jpg.webp', title: 'der-kunstfreund', cat: 'Bilder (1995-2008)', range: [1995,2008] },
    { filename: 'bilder-1995-2008/der-tod-als-spassmacher.jpg.webp', title: 'der-tod-als-spassmacher', cat: 'Bilder (1995-2008)', range: [1995,2008] },
    { filename: 'bilder-1995-2008/der-verutschte-hut.jpg.webp', title: 'der-verutschte-hut', cat: 'Bilder (1995-2008)', range: [1995,2008] },
    { filename: 'bilder-1995-2008/deutsche-malersage.jpg.webp', title: 'deutsche-malersage', cat: 'Bilder (1995-2008)', range: [1995,2008] },
    { filename: 'bilder-1995-2008/die-frage-der-macht.jpg.webp', title: 'die-frage-der-macht', cat: 'Bilder (1995-2008)', range: [1995,2008] },
    { filename: 'bilder-1995-2008/die-gefangenen.jpg.webp', title: 'die-gefangenen', cat: 'Bilder (1995-2008)', range: [1995,2008] },
    { filename: 'bilder-1995-2008/diese-welt-isr-eine-null.jpg.webp', title: 'diese-welt-isr-eine-null', cat: 'Bilder (1995-2008)', range: [1995,2008] },
    { filename: 'bilder-1995-2008/drehorgellied.jpg.webp', title: 'drehorgellied', cat: 'Bilder (1995-2008)', range: [1995,2008] },
    { filename: 'bilder-1995-2008/drei-irre-gingen-in-den-garten.jpg.webp', title: 'drei-irre-gingen-in-den-garten', cat: 'Bilder (1995-2008)', range: [1995,2008] },
    { filename: 'bilder-1995-2008/dritte-sekunde.jpg.webp', title: 'dritte-sekunde', cat: 'Bilder (1995-2008)', range: [1995,2008] },
    { filename: 'bilder-1995-2008/du-bist-maler1.jpg.webp', title: 'du-bist-maler1', cat: 'Bilder (1995-2008)', range: [1995,2008] },
    { filename: 'bilder-1995-2008/ein-altes-lied.jpg.webp', title: 'ein-altes-lied', cat: 'Bilder (1995-2008)', range: [1995,2008] },
    { filename: 'bilder-1995-2008/erinnerung.jpg.webp', title: 'erinnerung', cat: 'Bilder (1995-2008)', range: [1995,2008] },
    { filename: 'bilder-1995-2008/erleuchtung-in-der-wuste.jpg.webp', title: 'erleuchtung-in-der-wuste', cat: 'Bilder (1995-2008)', range: [1995,2008] },
    { filename: 'bilder-1995-2008/erste-sekunde.jpg.webp', title: 'erste-sekunde', cat: 'Bilder (1995-2008)', range: [1995,2008] },
    { filename: 'bilder-1995-2008/fruhling-im-hochgebirge.jpg.webp', title: 'fruhling-im-hochgebirge', cat: 'Bilder (1995-2008)', range: [1995,2008] },
    { filename: 'bilder-1995-2008/funfte-sekunde.jpg.webp', title: 'funfte-sekunde', cat: 'Bilder (1995-2008)', range: [1995,2008] },
    { filename: 'bilder-1995-2008/fur-drowlgla.jpg.webp', title: 'fur-drowlgla', cat: 'Bilder (1995-2008)', range: [1995,2008] },
    { filename: 'bilder-1995-2008/gebet-fur-die-gefangenen.jpg.webp', title: 'gebet-fur-die-gefangenen', cat: 'Bilder (1995-2008)', range: [1995,2008] },
    { filename: 'bilder-1995-2008/junger-maler.jpg.webp', title: 'junger-maler', cat: 'Bilder (1995-2008)', range: [1995,2008] },
    { filename: 'bilder-1995-2008/kleine-asiatische-gotter.jpg.webp', title: 'kleine-asiatische-gotter', cat: 'Bilder (1995-2008)', range: [1995,2008] },
    { filename: 'bilder-1995-2008/krieg-dem-krieg.jpg.webp', title: 'krieg-dem-krieg', cat: 'Bilder (1995-2008)', range: [1995,2008] },
    { filename: 'bilder-1995-2008/kriegsteilnehmer.jpg.webp', title: 'kriegsteilnehmer', cat: 'Bilder (1995-2008)', range: [1995,2008] },
    { filename: 'bilder-1995-2008/land-der-jugend.jpg.webp', title: 'land-der-jugend', cat: 'Bilder (1995-2008)', range: [1995,2008] },
    { filename: 'bilder-1995-2008/limbisches-system.jpg.webp', title: 'limbisches-system', cat: 'Bilder (1995-2008)', range: [1995,2008] },
    { filename: 'bilder-1995-2008/nachruf.jpg.webp', title: 'nachruf', cat: 'Bilder (1995-2008)', range: [1995,2008] },
    { filename: 'bilder-1995-2008/nacht.jpg.webp', title: 'nacht', cat: 'Bilder (1995-2008)', range: [1995,2008] },
    { filename: 'bilder-1995-2008/neunte-sekunde.jpg.webp', title: 'neunte-sekunde', cat: 'Bilder (1995-2008)', range: [1995,2008] },
    { filename: 'bilder-1995-2008/nitschewo.jpg.webp', title: 'nitschewo', cat: 'Bilder (1995-2008)', range: [1995,2008] },
    { filename: 'bilder-1995-2008/paradoxisten.jpg.webp', title: 'paradoxisten', cat: 'Bilder (1995-2008)', range: [1995,2008] },
    { filename: 'bilder-1995-2008/paris.jpg.webp', title: 'paris', cat: 'Bilder (1995-2008)', range: [1995,2008] },
    { filename: 'bilder-1995-2008/pelasgischer-schopfungsmythos.jpg.webp', title: 'pelasgischer-schopfungsmythos', cat: 'Bilder (1995-2008)', range: [1995,2008] },
    { filename: 'bilder-1995-2008/rausgewunken-und-erschossen.jpg.webp', title: 'rausgewunken-und-erschossen', cat: 'Bilder (1995-2008)', range: [1995,2008] },
    { filename: 'bilder-1995-2008/religionsunterricht.jpg.webp', title: 'religionsunterricht', cat: 'Bilder (1995-2008)', range: [1995,2008] },
    { filename: 'bilder-1995-2008/revolutions-ruckblick.jpg.webp', title: 'revolutions-ruckblick', cat: 'Bilder (1995-2008)', range: [1995,2008] },
    { filename: 'bilder-1995-2008/rheinundine.jpg.webp', title: 'rheinundine', cat: 'Bilder (1995-2008)', range: [1995,2008] },
    { filename: 'bilder-1995-2008/rom.jpg.webp', title: 'rom', cat: 'Bilder (1995-2008)', range: [1995,2008] },
    { filename: 'bilder-1995-2008/rota.jpg.webp', title: 'rota', cat: 'Bilder (1995-2008)', range: [1995,2008] },
    { filename: 'bilder-1995-2008/rote-melodie.jpg.webp', title: 'rote-melodie', cat: 'Bilder (1995-2008)', range: [1995,2008] },
    { filename: 'bilder-1995-2008/schwarze-locher.jpg.webp', title: 'schwarze-locher', cat: 'Bilder (1995-2008)', range: [1995,2008] },
    { filename: 'bilder-1995-2008/sechste-sekunde.jpg.webp', title: 'sechste-sekunde', cat: 'Bilder (1995-2008)', range: [1995,2008] },
    { filename: 'bilder-1995-2008/sekundartugened.jpg.webp', title: 'sekundartugened', cat: 'Bilder (1995-2008)', range: [1995,2008] },
    { filename: 'bilder-1995-2008/sexuelle-aufklaeung.jpg.webp', title: 'sexuelle-aufklaeung', cat: 'Bilder (1995-2008)', range: [1995,2008] },
    { filename: 'bilder-1995-2008/siebte-sekunde.jpg.webp', title: 'siebte-sekunde', cat: 'Bilder (1995-2008)', range: [1995,2008] },
    { filename: 'bilder-1995-2008/sonett-fur-blinde.jpg.webp', title: 'sonett-fur-blinde', cat: 'Bilder (1995-2008)', range: [1995,2008] },
    { filename: 'bilder-1995-2008/spazierganger-in-berlin.jpg.webp', title: 'spazierganger-in-berlin', cat: 'Bilder (1995-2008)', range: [1995,2008] },
    { filename: 'bilder-1995-2008/spieler.jpg.webp', title: 'spieler', cat: 'Bilder (1995-2008)', range: [1995,2008] },
    { filename: 'bilder-1995-2008/treue.jpg.webp', title: 'treue', cat: 'Bilder (1995-2008)', range: [1995,2008] },
    { filename: 'bilder-1995-2008/trunkenes-lied.jpg.webp', title: 'trunkenes-lied', cat: 'Bilder (1995-2008)', range: [1995,2008] },
    { filename: 'bilder-1995-2008/tunesischer-versager.jpg.webp', title: 'tunesischer-versager', cat: 'Bilder (1995-2008)', range: [1995,2008] },
    { filename: 'bilder-1995-2008/unser-militar.jpg.webp', title: 'unser-militar', cat: 'Bilder (1995-2008)', range: [1995,2008] },
    { filename: 'bilder-1995-2008/unser-taglich-brot.jpg.webp', title: 'unser-taglich-brot', cat: 'Bilder (1995-2008)', range: [1995,2008] },
    { filename: 'bilder-1995-2008/verfehlte-nacht.jpg.webp', title: 'verfehlte-nacht', cat: 'Bilder (1995-2008)', range: [1995,2008] },
    { filename: 'bilder-1995-2008/vergelts-gott.jpg.webp', title: 'vergelts-gott', cat: 'Bilder (1995-2008)', range: [1995,2008] },
    { filename: 'bilder-1995-2008/vierte-sekunde.jpg.webp', title: 'vierte-sekunde', cat: 'Bilder (1995-2008)', range: [1995,2008] },
    { filename: 'bilder-1995-2008/wenn-die-muse-kut.jpg.webp', title: 'wenn-die-muse-kut', cat: 'Bilder (1995-2008)', range: [1995,2008] },
    { filename: 'bilder-1995-2008/zeit-du-bist-entehrt.jpg.webp', title: 'zeit-du-bist-entehrt', cat: 'Bilder (1995-2008)', range: [1995,2008] },
    { filename: 'bilder-1995-2008/zerebrale-dominanz.jpg.webp', title: 'zerebrale-dominanz', cat: 'Bilder (1995-2008)', range: [1995,2008] },
    { filename: 'bilder-1995-2008/zweite-sekunde.jpg.webp', title: 'zweite-sekunde', cat: 'Bilder (1995-2008)', range: [1995,2008] },
    { filename: 'buro-2019/buro-1.jpg.webp', title: 'Büro 1', cat: 'Büro (2019)', range: [2019] },
    { filename: 'buro-2019/buro-10.jpg.webp', title: 'Büro 10', cat: 'Büro (2019)', range: [2019] },
    { filename: 'buro-2019/buro-2.jpg.webp', title: 'Büro 2', cat: 'Büro (2019)', range: [2019] },
    { filename: 'buro-2019/buro-3.jpg.webp', title: 'Büro 3', cat: 'Büro (2019)', range: [2019] },
    { filename: 'buro-2019/buro-4.jpg.webp', title: 'Büro 4', cat: 'Büro (2019)', range: [2019] },
    { filename: 'buro-2019/buro-5.jpg.webp', title: 'Büro 5', cat: 'Büro (2019)', range: [2019] },
    { filename: 'buro-2019/buro-6.jpg.webp', title: 'Büro 6', cat: 'Büro (2019)', range: [2019] },
    { filename: 'buro-2019/buro-7.jpg.webp', title: 'Büro 7', cat: 'Büro (2019)', range: [2019] },
    { filename: 'buro-2019/buro-8.jpg.webp', title: 'Büro 8', cat: 'Büro (2019)', range: [2019] },
    { filename: 'buro-2019/buro-9.jpg.webp', title: 'Büro 9', cat: 'Büro (2019)', range: [2019] },
    { filename: 'corona-art-2020/c0rona-art-07.jpg.webp', title: 'corona-art-07', cat: 'CORONA-ART (2020)', range: [2020] },
    { filename: 'corona-art-2020/corona-art-01.jpg.webp', title: 'corona-art-01', cat: 'CORONA-ART (2020)', range: [2020] },
    { filename: 'corona-art-2020/corona-art-010.jpg.webp', title: 'corona-art-010', cat: 'CORONA-ART (2020)', range: [2020] },
    { filename: 'corona-art-2020/corona-art-011.jpg.webp', title: 'corona-art-011', cat: 'CORONA-ART (2020)', range: [2020] },
    { filename: 'corona-art-2020/corona-art-012.jpg.webp', title: 'corona-art-012', cat: 'CORONA-ART (2020)', range: [2020] },
    { filename: 'corona-art-2020/corona-art-013.jpg.webp', title: 'corona-art-013', cat: 'CORONA-ART (2020)', range: [2020] },
    { filename: 'corona-art-2020/corona-art-014.jpg.webp', title: 'corona-art-014', cat: 'CORONA-ART (2020)', range: [2020] },
    { filename: 'corona-art-2020/corona-art-015.jpg.webp', title: 'corona-art-015', cat: 'CORONA-ART (2020)', range: [2020] },
    { filename: 'corona-art-2020/corona-art-016.jpg.webp', title: 'corona-art-016', cat: 'CORONA-ART (2020)', range: [2020] },
    { filename: 'corona-art-2020/corona-art-017.jpg.webp', title: 'corona-art-017', cat: 'CORONA-ART (2020)', range: [2020] },
    { filename: 'corona-art-2020/corona-art-018.jpg.webp', title: 'corona-art-018', cat: 'CORONA-ART (2020)', range: [2020] },
    { filename: 'corona-art-2020/corona-art-019.jpg.webp', title: 'corona-art-019', cat: 'CORONA-ART (2020)', range: [2020] },
    { filename: 'corona-art-2020/corona-art-02.jpg.webp', title: 'corona-art-02', cat: 'CORONA-ART (2020)', range: [2020] },
    { filename: 'corona-art-2020/corona-art-020.jpg.webp', title: 'corona-art-020', cat: 'CORONA-ART (2020)', range: [2020] },
    { filename: 'corona-art-2020/corona-art-021.jpg.webp', title: 'corona-art-021', cat: 'CORONA-ART (2020)', range: [2020] },
    { filename: 'corona-art-2020/corona-art-022.jpg.webp', title: 'corona-art-022', cat: 'CORONA-ART (2020)', range: [2020] },
    { filename: 'corona-art-2020/corona-art-023.jpg.webp', title: 'corona-art-023', cat: 'CORONA-ART (2020)', range: [2020] },
    { filename: 'corona-art-2020/corona-art-024.jpg.webp', title: 'corona-art-024', cat: 'CORONA-ART (2020)', range: [2020] },
    { filename: 'corona-art-2020/corona-art-025.jpg.webp', title: 'corona-art-025', cat: 'CORONA-ART (2020)', range: [2020] },
    { filename: 'corona-art-2020/corona-art-03.jpg.webp', title: 'corona-art-03', cat: 'CORONA-ART (2020)', range: [2020] },
    { filename: 'corona-art-2020/corona-art-05.jpg.webp', title: 'corona-art-05', cat: 'CORONA-ART (2020)', range: [2020] },
    { filename: 'corona-art-2020/corona-art-06.jpg.webp', title: 'corona-art-06', cat: 'CORONA-ART (2020)', range: [2020] },
    { filename: 'corona-art-2020/corona-art-08.jpg.webp', title: 'corona-art-08', cat: 'CORONA-ART (2020)', range: [2020] },
    { filename: 'corona-art-2020/coronaart-04.jpg.webp', title: 'coronaart-04', cat: 'CORONA-ART (2020)', range: [2020] },
    { filename: 'das-verlorene-jahr2017/das-verlorene-jahr-1.jpg.webp', title: 'das-verlorene-jahr-1', cat: 'Das verlorene Jahr(2017)', range: [2017] },
    { filename: 'das-verlorene-jahr2017/das-verlorene-jahr-2.jpg.webp', title: 'das-verlorene-jahr-2', cat: 'Das verlorene Jahr(2017)', range: [2017] },
    { filename: 'das-verlorene-jahr2017/das-verlorene-jahr-3.jpg.webp', title: 'das-verlorene-jahr-3', cat: 'Das verlorene Jahr(2017)', range: [2017] },
    { filename: 'das-verlorene-jahr2017/das-verlorene-jahr-4.jpg.webp', title: 'das-verlorene-jahr-4', cat: 'Das verlorene Jahr(2017)', range: [2017] },
    { filename: 'das-verlorene-jahr2017/das-verlorene-jahr-5.jpg.webp', title: 'das-verlorene-jahr-5', cat: 'Das verlorene Jahr(2017)', range: [2017] },
    { filename: 'das-verlorene-jahr2017/das-verlorene-jahr-6.jpg.webp', title: 'das-verlorene-jahr-6', cat: 'Das verlorene Jahr(2017)', range: [2017] },
    { filename: 'das-verlorene-jahr2017/das-verlorene-jahr-7.jpg.webp', title: 'das-verlorene-jahr-7', cat: 'Das verlorene Jahr(2017)', range: [2017] },
    { filename: 'das-verlorene-jahr2017/das-verlorene-jahr-8.jpg.webp', title: 'das-verlorene-jahr-8', cat: 'Das verlorene Jahr(2017)', range: [2017] },
    { filename: 'das-verlorene-jahr2017/das-verlorene-jahr-9.jpg.webp', title: 'das-verlorene-jahr-9', cat: 'Das verlorene Jahr(2017)', range: [2017] },
    { filename: 'der-himmel-uber-peking-2014/peking-1.png.webp', title: 'peking-1', cat: 'Der Himmel über Peking (2014)', range: [2014] },
    { filename: 'der-himmel-uber-peking-2014/peking-11.png.webp', title: 'peking-11', cat: 'Der Himmel über Peking (2014)', range: [2014] },
    { filename: 'der-himmel-uber-peking-2014/peking-12.png.webp', title: 'peking-12', cat: 'Der Himmel über Peking (2014)', range: [2014] },
    { filename: 'der-himmel-uber-peking-2014/peking-13.png.webp', title: 'peking-13', cat: 'Der Himmel über Peking (2014)', range: [2014] },
    { filename: 'der-himmel-uber-peking-2014/peking-14.png.webp', title: 'peking-14', cat: 'Der Himmel über Peking (2014)', range: [2014] },
    { filename: 'der-himmel-uber-peking-2014/peking-2.png.webp', title: 'peking-2', cat: 'Der Himmel über Peking (2014)', range: [2014] },
    { filename: 'der-himmel-uber-peking-2014/peking-3.png.webp', title: 'peking-3', cat: 'Der Himmel über Peking (2014)', range: [2014] },
    { filename: 'der-himmel-uber-peking-2014/peking-4.png.webp', title: 'peking-4', cat: 'Der Himmel über Peking (2014)', range: [2014] },
    { filename: 'der-himmel-uber-peking-2014/peking-5.png.webp', title: 'peking-5', cat: 'Der Himmel über Peking (2014)', range: [2014] },
    { filename: 'der-himmel-uber-peking-2014/peking-6.png.webp', title: 'peking-6', cat: 'Der Himmel über Peking (2014)', range: [2014] },
    { filename: 'der-himmel-uber-peking-2014/peking-7.png.webp', title: 'peking-7', cat: 'Der Himmel über Peking (2014)', range: [2014] },
    { filename: 'der-himmel-uber-peking-2014/peking-8.png.webp', title: 'peking-8', cat: 'Der Himmel über Peking (2014)', range: [2014] },
    { filename: 'der-himmel-uber-peking-2014/peking-9.png.webp', title: 'peking-9', cat: 'Der Himmel über Peking (2014)', range: [2014] },
    { filename: 'der-himmel-uber-peking-2014/verfall-10.png.webp', title: 'verfall-10', cat: 'Der Himmel über Peking (2014)', range: [2014] },
    { filename: 'der-schein-trugt-2018/der-schein-trugt-1.jpg.webp', title: 'der-schein-trugt-1', cat: 'Der Schein trügt (2018)', range: [2018] },
    { filename: 'der-schein-trugt-2018/der-schein-trugt-10.jpg.webp', title: 'der-schein-trugt-10', cat: 'Der Schein trügt (2018)', range: [2018] },
    { filename: 'der-schein-trugt-2018/der-schein-trugt-11.jpg.webp', title: 'der-schein-trugt-11', cat: 'Der Schein trügt (2018)', range: [2018] },
    { filename: 'der-schein-trugt-2018/der-schein-trugt-12.jpg.webp', title: 'der-schein-trugt-12', cat: 'Der Schein trügt (2018)', range: [2018] },
    { filename: 'der-schein-trugt-2018/der-schein-trugt-13.jpg.webp', title: 'der-schein-trugt-13', cat: 'Der Schein trügt (2018)', range: [2018] },
    { filename: 'der-schein-trugt-2018/der-schein-trugt-14.jpg.webp', title: 'der-schein-trugt-14', cat: 'Der Schein trügt (2018)', range: [2018] },
    { filename: 'der-schein-trugt-2018/der-schein-trugt-15.jpg.webp', title: 'der-schein-trugt-15', cat: 'Der Schein trügt (2018)', range: [2018] },
    { filename: 'der-schein-trugt-2018/der-schein-trugt-16.jpg.webp', title: 'der-schein-trugt-16', cat: 'Der Schein trügt (2018)', range: [2018] },
    { filename: 'der-schein-trugt-2018/der-schein-trugt-17.jpg.webp', title: 'der-schein-trugt-17', cat: 'Der Schein trügt (2018)', range: [2018] },
    { filename: 'der-schein-trugt-2018/der-schein-trugt-18.jpg.webp', title: 'der-schein-trugt-18', cat: 'Der Schein trügt (2018)', range: [2018] },
    { filename: 'der-schein-trugt-2018/der-schein-trugt-19.jpg.webp', title: 'der-schein-trugt-19', cat: 'Der Schein trügt (2018)', range: [2018] },
    { filename: 'der-schein-trugt-2018/der-schein-trugt-2.jpg.webp', title: 'der-schein-trugt-2', cat: 'Der Schein trügt (2018)', range: [2018] },
    { filename: 'der-schein-trugt-2018/der-schein-trugt-20.jpg.webp', title: 'der-schein-trugt-20', cat: 'Der Schein trügt (2018)', range: [2018] },
    { filename: 'der-schein-trugt-2018/der-schein-trugt-21.jpg.webp', title: 'der-schein-trugt-21', cat: 'Der Schein trügt (2018)', range: [2018] },
    { filename: 'der-schein-trugt-2018/der-schein-trugt-22.jpg.webp', title: 'der-schein-trugt-22', cat: 'Der Schein trügt (2018)', range: [2018] },
    { filename: 'der-schein-trugt-2018/der-schein-trugt-23.jpg.webp', title: 'der-schein-trugt-23', cat: 'Der Schein trügt (2018)', range: [2018] },
    { filename: 'der-schein-trugt-2018/der-schein-trugt-24.jpg.webp', title: 'der-schein-trugt-24', cat: 'Der Schein trügt (2018)', range: [2018] },
    { filename: 'der-schein-trugt-2018/der-schein-trugt-25.jpg.webp', title: 'der-schein-trugt-25', cat: 'Der Schein trügt (2018)', range: [2018] },
    { filename: 'der-schein-trugt-2018/der-schein-trugt-26.jpg.webp', title: 'der-schein-trugt-26', cat: 'Der Schein trügt (2018)', range: [2018] },
    { filename: 'der-schein-trugt-2018/der-schein-trugt-27.jpg.webp', title: 'der-schein-trugt-27', cat: 'Der Schein trügt (2018)', range: [2018] },
    { filename: 'der-schein-trugt-2018/der-schein-trugt-28.jpg.webp', title: 'der-schein-trugt-28', cat: 'Der Schein trügt (2018)', range: [2018] },
    { filename: 'der-schein-trugt-2018/der-schein-trugt-3.jpg.webp', title: 'der-schein-trugt-3', cat: 'Der Schein trügt (2018)', range: [2018] },
    { filename: 'der-schein-trugt-2018/der-schein-trugt-30.jpg.webp', title: 'der-schein-trugt-30', cat: 'Der Schein trügt (2018)', range: [2018] },
    { filename: 'der-schein-trugt-2018/der-schein-trugt-31.jpg.webp', title: 'der-schein-trugt-31', cat: 'Der Schein trügt (2018)', range: [2018] },
    { filename: 'der-schein-trugt-2018/der-schein-trugt-32.jpg.webp', title: 'der-schein-trugt-32', cat: 'Der Schein trügt (2018)', range: [2018] },
    { filename: 'der-schein-trugt-2018/der-schein-trugt-33.jpg.webp', title: 'der-schein-trugt-33', cat: 'Der Schein trügt (2018)', range: [2018] },
    { filename: 'der-schein-trugt-2018/der-schein-trugt-34.jpg.webp', title: 'der-schein-trugt-34', cat: 'Der Schein trügt (2018)', range: [2018] },
    { filename: 'der-schein-trugt-2018/der-schein-trugt-35.jpg.webp', title: 'der-schein-trugt-35', cat: 'Der Schein trügt (2018)', range: [2018] },
    { filename: 'der-schein-trugt-2018/der-schein-trugt-36.jpg.webp', title: 'der-schein-trugt-36', cat: 'Der Schein trügt (2018)', range: [2018] },
    { filename: 'der-schein-trugt-2018/der-schein-trugt-37.jpg.webp', title: 'der-schein-trugt-37', cat: 'Der Schein trügt (2018)', range: [2018] },
    { filename: 'der-schein-trugt-2018/der-schein-trugt-38.jpg.webp', title: 'der-schein-trugt-38', cat: 'Der Schein trügt (2018)', range: [2018] },
    { filename: 'der-schein-trugt-2018/der-schein-trugt-39.jpg.webp', title: 'der-schein-trugt-39', cat: 'Der Schein trügt (2018)', range: [2018] },
    { filename: 'der-schein-trugt-2018/der-schein-trugt-4.jpg.webp', title: 'der-schein-trugt-4', cat: 'Der Schein trügt (2018)', range: [2018] },
    { filename: 'der-schein-trugt-2018/der-schein-trugt-6.jpg.webp', title: 'der-schein-trugt-6', cat: 'Der Schein trügt (2018)', range: [2018] },
    { filename: 'der-schein-trugt-2018/der-schein-trugt-7.jpg.webp', title: 'der-schein-trugt-7', cat: 'Der Schein trügt (2018)', range: [2018] },
    { filename: 'der-schein-trugt-2018/der-schein-trugt-8.jpg.webp', title: 'der-schein-trugt-8', cat: 'Der Schein trügt (2018)', range: [2018] },
    { filename: 'der-schein-trugt-2018/der-schein-trugt-9.jpg.webp', title: 'der-schein-trugt-9', cat: 'Der Schein trügt (2018)', range: [2018] },
    { filename: 'dovcenko2-2022/dsc_0877a.jpg.webp', title: 'dsc_0877a', cat: 'Dovcenko2 (2022)', range: [2022] },
    { filename: 'dovcenko2-2022/dsc_0881a.jpg.webp', title: 'dsc_0881a', cat: 'Dovcenko2 (2022)', range: [2022] },
    { filename: 'dovcenko2-2022/dsc_0882.JPG.webp', title: 'dsc_0882', cat: 'Dovcenko2 (2022)', range: [2022] },
    { filename: 'dovcenko2-2022/dsc_0883.JPG.webp', title: 'dsc_0883', cat: 'Dovcenko2 (2022)', range: [2022] },
    { filename: 'dovcenko2-2022/dsc_0886.JPG.webp', title: 'dsc_0886', cat: 'Dovcenko2 (2022)', range: [2022] },
    { filename: 'dovcenko2-2022/dsc_0888.JPG.webp', title: 'dsc_0888', cat: 'Dovcenko2 (2022)', range: [2022] },
    { filename: 'dovcenko2-2022/dsc_0889a.jpg.webp', title: 'dsc_0889a', cat: 'Dovcenko2 (2022)', range: [2022] },
    { filename: 'dovcenko2-2022/dsc_0892.JPG.webp', title: 'dsc_0892', cat: 'Dovcenko2 (2022)', range: [2022] },
    { filename: 'dovcenko2-2022/dsc_0893a.jpg.webp', title: 'dsc_0893a', cat: 'Dovcenko2 (2022)', range: [2022] },
    { filename: 'dovcenko2-2022/dsc_0894a.jpg.webp', title: 'dsc_0894a', cat: 'Dovcenko2 (2022)', range: [2022] },
    { filename: 'dystopie-2011/dystopie-1.jpg.webp', title: 'dystopie-1', cat: 'Dystopie (2011)', range: [2011] },
    { filename: 'dystopie-2011/dystopie-2.jpg.webp', title: 'dystopie-2', cat: 'Dystopie (2011)', range: [2011] },
    { filename: 'dystopie-2011/dystopie-3.jpg.webp', title: 'dystopie-3', cat: 'Dystopie (2011)', range: [2011] },
    { filename: 'dystopie-2011/dystopie-4.jpg.webp', title: 'dystopie-4', cat: 'Dystopie (2011)', range: [2011] },
    { filename: 'dystopie-2011/dystopie-5.jpg.webp', title: 'dystopie-5', cat: 'Dystopie (2011)', range: [2011] },
    { filename: 'dystopie-2011/dystopie-6.jpg.webp', title: 'dystopie-6', cat: 'Dystopie (2011)', range: [2011] },
    { filename: 'dystopie-2011/dystopie-7.jpg.webp', title: 'dystopie-7', cat: 'Dystopie (2011)', range: [2011] },
    { filename: 'dystopie-2011/dystopie-8.jpg.webp', title: 'dystopie-8', cat: 'Dystopie (2011)', range: [2011] },
    { filename: 'dystopie-2011/dystopie-9.jpg.webp', title: 'dystopie-9', cat: 'Dystopie (2011)', range: [2011] },
    { filename: 'freiheitskampf-2018/freiheitskampf-1.jpg.webp', title: 'freiheitskampf-1', cat: 'Freiheitskampf (2018)', range: [2018] },
    { filename: 'freiheitskampf-2018/freiheitskampf-2.jpg.webp', title: 'freiheitskampf-2', cat: 'Freiheitskampf (2018)', range: [2018] },
    { filename: 'freiheitskampf-2018/freiheitskampf-3.jpg.webp', title: 'freiheitskampf-3', cat: 'Freiheitskampf (2018)', range: [2018] },
    { filename: 'freiheitskampf-2018/freiheitskampf-4.jpg.webp', title: 'freiheitskampf-4', cat: 'Freiheitskampf (2018)', range: [2018] },
    { filename: 'geruchtekuche-2017/geruchtekuche-1.jpg.webp', title: 'geruchtekuche-1', cat: 'Gerüchteküche (2017)', range: [2017] },
    { filename: 'geruchtekuche-2017/geruchtekuche-2.jpg.webp', title: 'geruchtekuche-2', cat: 'Gerüchteküche (2017)', range: [2017] },
    { filename: 'geruchtekuche-2017/geruchtekuche-3.jpg.webp', title: 'geruchtekuche-3', cat: 'Gerüchteküche (2017)', range: [2017] },
    { filename: 'hallgatas-2014/hallgatas-1.jpg.webp', title: 'hallgatas-1', cat: 'Hallgatás (2014)', range: [2014] },
    { filename: 'hallgatas-2014/hallgatas-2.jpg.webp', title: 'hallgatas-2', cat: 'Hallgatás (2014)', range: [2014] },
    { filename: 'hallgatas-2014/hallgatas-3.jpg.webp', title: 'hallgatas-3', cat: 'Hallgatás (2014)', range: [2014] },
    { filename: 'hallgatas-2014/hallgatas-4.jpg.webp', title: 'hallgatas-4', cat: 'Hallgatás (2014)', range: [2014] },
    { filename: 'hallgatas-2014/hallgatas-5.jpg.webp', title: 'hallgatas-5', cat: 'Hallgatás (2014)', range: [2014] },
    { filename: 'hallgatas-2014/hallgatas-6.jpg.webp', title: 'hallgatas-6', cat: 'Hallgatás (2014)', range: [2014] },
    { filename: 'hallgatas-2014/hallgatas-7.jpg.webp', title: 'hallgatas-7', cat: 'Hallgatás (2014)', range: [2014] },
    { filename: 'hallgatas-2014/hallgatas-8.jpg.webp', title: 'hallgatas-8', cat: 'Hallgatás (2014)', range: [2014] },
    { filename: 'hallgatas-2014/schweigen_in_esztergom.jpg.webp', title: 'schweigen_in_esztergom', cat: 'Hallgatás (2014)', range: [2014] },
    { filename: 'kunst-und-so-2000-2021/amerikaner-in-paris.jpg.webp', title: 'amerikaner-in-paris', cat: 'Kunst und so (2000-2021)', range: [2000,2021] },
    { filename: 'kunst-und-so-2000-2021/der-dichter-bei-der-arbeit.jpg.webp', title: 'der-dichter-bei-der-arbeit', cat: 'Kunst und so (2000-2021)', range: [2000,2021] },
    { filename: 'kunst-und-so-2000-2021/dichtkunst.jpg.webp', title: 'dichtkunst', cat: 'Kunst und so (2000-2021)', range: [2000,2021] },
    { filename: 'kunst-und-so-2000-2021/die-deplacierten.jpg.webp', title: 'die-deplacierten', cat: 'Kunst und so (2000-2021)', range: [2000,2021] },
    { filename: 'kunst-und-so-2000-2021/die-ruckkehr-des-vaters.jpg.webp', title: 'die-ruckkehr-des-vaters', cat: 'Kunst und so (2000-2021)', range: [2000,2021] },
    { filename: 'kunst-und-so-2000-2021/europa-vor-dem-regen.jpg.webp', title: 'europa-vor-dem-regen', cat: 'Kunst und so (2000-2021)', range: [2000,2021] },
    { filename: 'kunst-und-so-2000-2021/nur-die-ruhe.JPG.webp', title: 'nur-die-ruhe', cat: 'Kunst und so (2000-2021)', range: [2000,2021] },
    { filename: 'kunst-und-so-2000-2021/ware-es-nicht-schon-dabei-zu-sein.jpg.webp', title: 'ware-es-nicht-schon-dabei-zu-sein', cat: 'Kunst und so (2000-2021)', range: [2000,2021] },
    { filename: 'la-belle-et-la-bete-2009/belle-raconte-son-histoire.jpg.webp', title: 'belle-raconte-son-histoire', cat: 'LA BELLE ET LA BETE (2009)', range: [2009] },
    { filename: 'la-belle-et-la-bete-2009/ils-ont-bout.jpg.webp', title: 'ils-ont-bout', cat: 'LA BELLE ET LA BETE (2009)', range: [2009] },
    { filename: 'la-belle-et-la-bete-2009/la-confiance-de-la-bete-en-la-belle.jpg.webp', title: 'la-confiance-de-la-bete-en-la-belle', cat: 'LA BELLE ET LA BETE (2009)', range: [2009] },
    { filename: 'la-belle-et-la-bete-2009/la-domaine-de-la-bete.jpg.webp', title: 'la-domaine-de-la-bete', cat: 'LA BELLE ET LA BETE (2009)', range: [2009] },
    { filename: 'la-belle-et-la-bete-2009/la-retour-du-pere.jpg.webp', title: 'la-retour-du-pere', cat: 'LA BELLE ET LA BETE (2009)', range: [2009] },
    { filename: 'la-belle-et-la-bete-2009/le-magnifque-apparait.jpg.webp', title: 'le-magnifque-apparait', cat: 'LA BELLE ET LA BETE (2009)', range: [2009] },
    { filename: 'la-belle-et-la-bete-2009/les-tourments-de-la-bete.jpg.webp', title: 'les-tourments-de-la-bete', cat: 'LA BELLE ET LA BETE (2009)', range: [2009] },
    { filename: 'la-belle-et-la-bete-2009/promenad-dans-le-jardin.jpg.webp', title: 'promenad-dans-le-jardin', cat: 'LA BELLE ET LA BETE (2009)', range: [2009] },
    { filename: 'la-belle-et-la-bete-2009/vous-me-tuez.jpg.webp', title: 'vous-me-tuez', cat: 'LA BELLE ET LA BETE (2009)', range: [2009] },
    { filename: 'la-trasformazione-2017/das-gesetz.jpg.webp', title: 'das-gesetz', cat: 'La Trasformazione (2017)', range: [2017] },
    { filename: 'la-trasformazione-2017/fruhling.jpg.webp', title: 'fruhling', cat: 'La Trasformazione (2017)', range: [2017] },
    { filename: 'la-trasformazione-2017/religionsunterricht.jpg.webp', title: 'religionsunterricht', cat: 'La Trasformazione (2017)', range: [2017] },
    { filename: 'reformation-2017/reformation-1.jpg.webp', title: 'reformation-1', cat: 'Reformation (2017)', range: [2017] },
    { filename: 'reformation-2017/reformation-2.jpg.webp', title: 'reformation-2', cat: 'Reformation (2017)', range: [2017] },
    { filename: 'reformation-2017/reformation-3.jpg.webp', title: 'reformation-3', cat: 'Reformation (2017)', range: [2017] },
    { filename: 'reformation-2017/reformation-4.jpg.webp', title: 'reformation-4', cat: 'Reformation (2017)', range: [2017] },
    { filename: 'reformation-2017/reformation-5.jpg.webp', title: 'reformation-5', cat: 'Reformation (2017)', range: [2017] },
    { filename: 'reformation-2017/reformation-6.jpg.webp', title: 'reformation-6', cat: 'Reformation (2017)', range: [2017] },
    { filename: 'religionsunterricht-2-2013-2014/armes-berlin.jpg.webp', title: 'armes-berlin', cat: 'Religionsunterricht 2 (2013-2014)', range: [2013,2014] },
    { filename: 'religionsunterricht-2-2013-2014/bergmannslied.jpg.webp', title: 'bergmannslied', cat: 'Religionsunterricht 2 (2013-2014)', range: [2013,2014] },
    { filename: 'religionsunterricht-2-2013-2014/bilanz.jpg.webp', title: 'bilanz', cat: 'Religionsunterricht 2 (2013-2014)', range: [2013,2014] },
    { filename: 'religionsunterricht-2-2013-2014/das-gesetz.jpg.webp', title: 'das-gesetz', cat: 'Religionsunterricht 2 (2013-2014)', range: [2013,2014] },
    { filename: 'religionsunterricht-2-2013-2014/das-lied-von-der-gleichgulgtigkeit.jpg.webp', title: 'das-lied-von-der-gleichgulgtigkeit', cat: 'Religionsunterricht 2 (2013-2014)', range: [2013,2014] },
    { filename: 'religionsunterricht-2-2013-2014/das-schweigen.jpg.webp', title: 'das-schweigen', cat: 'Religionsunterricht 2 (2013-2014)', range: [2013,2014] },
    { filename: 'religionsunterricht-2-2013-2014/die-bekehrung.jpg.webp', title: 'die-bekehrung', cat: 'Religionsunterricht 2 (2013-2014)', range: [2013,2014] },
    { filename: 'religionsunterricht-2-2013-2014/einst-saen-wir-im-zuchthaus-und-lagen-in-ketten.jpg.webp', title: 'einst-saen-wir-im-zuchthaus-und-lagen-in-ketten', cat: 'Religionsunterricht 2 (2013-2014)', range: [2013,2014] },
    { filename: 'religionsunterricht-2-2013-2014/es-ist-krieg.jpg.webp', title: 'es-ist-krieg', cat: 'Religionsunterricht 2 (2013-2014)', range: [2013,2014] },
    { filename: 'religionsunterricht-2-2013-2014/philosophie.jpg.webp', title: 'philosophie', cat: 'Religionsunterricht 2 (2013-2014)', range: [2013,2014] },
    { filename: 'religionsunterricht-2-2013-2014/religionsunterricht.jpg.webp', title: 'religionsunterricht', cat: 'Religionsunterricht 2 (2013-2014)', range: [2013,2014] },
    { filename: 'religionsunterricht-2-2013-2014/sehnsucht-nach-der-sehnsucht.jpg.webp', title: 'sehnsucht-nach-der-sehnsucht', cat: 'Religionsunterricht 2 (2013-2014)', range: [2013,2014] },
    { filename: 'religionsunterricht-2-2013-2014/wunsche.jpg.webp', title: 'wunsche', cat: 'Religionsunterricht 2 (2013-2014)', range: [2013,2014] },
    { filename: 'religionsunterricht-2013/armes-berlin.jpg.webp', title: 'armes-berlin', cat: 'Religionsunterricht (2013)', range: [2013] },
    { filename: 'religionsunterricht-2013/das-gesetz.jpg.webp', title: 'das-gesetz', cat: 'Religionsunterricht (2013)', range: [2013] },
    { filename: 'religionsunterricht-2013/das-schweigen.jpg.webp', title: 'das-schweigen', cat: 'Religionsunterricht (2013)', range: [2013] },
    { filename: 'religionsunterricht-2013/die-bekehrung.jpg.webp', title: 'die-bekehrung', cat: 'Religionsunterricht (2013)', range: [2013] },
    { filename: 'religionsunterricht-2013/drei-irre-gingen-in-den-garten.jpg.webp', title: 'drei-irre-gingen-in-den-garten', cat: 'Religionsunterricht (2013)', range: [2013] },
    { filename: 'religionsunterricht-2013/einst-saen-wir-im-zuchthaus.jpg.webp', title: 'einst-saen-wir-im-zuchthaus', cat: 'Religionsunterricht (2013)', range: [2013] },
    { filename: 'religionsunterricht-2013/fruhling.jpg.webp', title: 'fruhling', cat: 'Religionsunterricht (2013)', range: [2013] },
    { filename: 'religionsunterricht-2013/religionsunterricht.jpg.webp', title: 'religionsunterricht', cat: 'Religionsunterricht (2013)', range: [2013] },
    { filename: 'religionsunterricht-2013/sehnsucht.jpg.webp', title: 'sehnsucht', cat: 'Religionsunterricht (2013)', range: [2013] },
    { filename: 'religionsunterricht-2013/sist-krieg.jpg.webp', title: 'sist-krieg', cat: 'Religionsunterricht (2013)', range: [2013] },
    { filename: 'verfall-2019-2021/thomas_verfall_003.png.webp', title: 'thomas_verfall_003', cat: 'Verfall (2019-2021)', range: [2019,2021] },
    { filename: 'verfall-2019-2021/thomas_verfall_011.png.webp', title: 'thomas_verfall_011', cat: 'Verfall (2019-2021)', range: [2019,2021] },
    { filename: 'verfall-2019-2021/thomas_verfall_016.png.webp', title: 'thomas_verfall_016', cat: 'Verfall (2019-2021)', range: [2019,2021] },
    { filename: 'verfall-2019-2021/thomas_verfall_033.png.webp', title: 'thomas_verfall_033', cat: 'Verfall (2019-2021)', range: [2019,2021] },
    { filename: 'verfall-2019-2021/thomas_verfall_051.png.webp', title: 'thomas_verfall_051', cat: 'Verfall (2019-2021)', range: [2019,2021] },
    { filename: 'verfall-2019-2021/thomas_verfall_084.png.webp', title: 'thomas_verfall_084', cat: 'Verfall (2019-2021)', range: [2019,2021] },
    { filename: 'verfall-2019-2021/thomas_verfall_096.png.webp', title: 'thomas_verfall_096', cat: 'Verfall (2019-2021)', range: [2019,2021] },
    { filename: 'verfall-2019-2021/thomas_verfall_097.png.webp', title: 'thomas_verfall_097', cat: 'Verfall (2019-2021)', range: [2019,2021] },
    { filename: 'verfall-2019-2021/thomas_verfall_108.png.webp', title: 'thomas_verfall_108', cat: 'Verfall (2019-2021)', range: [2019,2021] },
    { filename: 'verfall-2019-2021/thomas_verfall_110.png.webp', title: 'thomas_verfall_110', cat: 'Verfall (2019-2021)', range: [2019,2021] },
    { filename: 'verfall-2019-2021/thomas_verfall_113.png.webp', title: 'thomas_verfall_113', cat: 'Verfall (2019-2021)', range: [2019,2021] },
    { filename: 'verfall-2019-2021/thomas_verfall_118.png.webp', title: 'thomas_verfall_118', cat: 'Verfall (2019-2021)', range: [2019,2021] },
    { filename: 'verfall-2019-2021/thomas_verfall_136.png.webp', title: 'thomas_verfall_136', cat: 'Verfall (2019-2021)', range: [2019,2021] },
    { filename: 'verfall-2019-2021/thomas_verfall_138.png.webp', title: 'thomas_verfall_138', cat: 'Verfall (2019-2021)', range: [2019,2021] },
    { filename: 'wege-aus-dem-ghetto-2016/wege-aus-dem-ghetto-1.jpg.webp', title: 'wege-aus-dem-ghetto-1', cat: 'Wege aus dem Ghetto (2016)', range: [2016] },
    { filename: 'wege-aus-dem-ghetto-2016/wege-aus-dem-ghetto-2.jpg.webp', title: 'wege-aus-dem-ghetto-2', cat: 'Wege aus dem Ghetto (2016)', range: [2016] },
    { filename: 'wege-aus-dem-ghetto-2016/wege-aus-dem-ghetto-3.jpg.webp', title: 'wege-aus-dem-ghetto-3', cat: 'Wege aus dem Ghetto (2016)', range: [2016] },
    { filename: 'wege-aus-dem-ghetto-2016/wege-aus-dem-ghetto-4.jpg.webp', title: 'wege-aus-dem-ghetto-4', cat: 'Wege aus dem Ghetto (2016)', range: [2016] },
    { filename: 'wille-und-vorstellung-2018/freiheitskampf-2.jpg.webp', title: 'freiheitskampf-2', cat: 'Wille und Vorstellung (2018)', range: [2018] },
    { filename: 'wille-und-vorstellung-2018/freiheitskampf-3.jpg.webp', title: 'freiheitskampf-3', cat: 'Wille und Vorstellung (2018)', range: [2018] },
    { filename: 'wille-und-vorstellung-2018/freiheitskampf-4.jpg.webp', title: 'freiheitskampf-4', cat: 'Wille und Vorstellung (2018)', range: [2018] },
    { filename: 'wille-und-vorstellung-2018/freiheitskampf1.jpg.webp', title: 'freiheitskampf1', cat: 'Wille und Vorstellung (2018)', range: [2018] },
    { filename: 'wille-und-vorstellung-2018/wille-und-vorstellung-1.jpg.webp', title: 'wille-und-vorstellung-1', cat: 'Wille und Vorstellung (2018)', range: [2018] },
    { filename: 'wille-und-vorstellung-2018/wille-und-vorstellung-10.jpg.webp', title: 'wille-und-vorstellung-10', cat: 'Wille und Vorstellung (2018)', range: [2018] },
    { filename: 'wille-und-vorstellung-2018/wille-und-vorstellung-11.jpg.webp', title: 'wille-und-vorstellung-11', cat: 'Wille und Vorstellung (2018)', range: [2018] },
    { filename: 'wille-und-vorstellung-2018/wille-und-vorstellung-12.jpg.webp', title: 'wille-und-vorstellung-12', cat: 'Wille und Vorstellung (2018)', range: [2018] },
    { filename: 'wille-und-vorstellung-2018/wille-und-vorstellung-13.jpg.webp', title: 'wille-und-vorstellung-13', cat: 'Wille und Vorstellung (2018)', range: [2018] },
    { filename: 'wille-und-vorstellung-2018/wille-und-vorstellung-14.jpg.webp', title: 'wille-und-vorstellung-14', cat: 'Wille und Vorstellung (2018)', range: [2018] },
    { filename: 'wille-und-vorstellung-2018/wille-und-vorstellung-15.jpg.webp', title: 'wille-und-vorstellung-15', cat: 'Wille und Vorstellung (2018)', range: [2018] },
    { filename: 'wille-und-vorstellung-2018/wille-und-vorstellung-16.jpg.webp', title: 'wille-und-vorstellung-16', cat: 'Wille und Vorstellung (2018)', range: [2018] },
    { filename: 'wille-und-vorstellung-2018/wille-und-vorstellung-17.jpg.webp', title: 'wille-und-vorstellung-17', cat: 'Wille und Vorstellung (2018)', range: [2018] },
    { filename: 'wille-und-vorstellung-2018/wille-und-vorstellung-2.jpg.webp', title: 'wille-und-vorstellung-2', cat: 'Wille und Vorstellung (2018)', range: [2018] },
    { filename: 'wille-und-vorstellung-2018/wille-und-vorstellung-3.jpg.webp', title: 'wille-und-vorstellung-3', cat: 'Wille und Vorstellung (2018)', range: [2018] },
    { filename: 'wille-und-vorstellung-2018/wille-und-vorstellung-5.jpg.webp', title: 'wille-und-vorstellung-5', cat: 'Wille und Vorstellung (2018)', range: [2018] },
    { filename: 'wille-und-vorstellung-2018/wille-und-vorstellung-6.jpg.webp', title: 'wille-und-vorstellung-6', cat: 'Wille und Vorstellung (2018)', range: [2018] },
    { filename: 'wille-und-vorstellung-2018/wille-und-vorstellung-7.jpg.webp', title: 'wille-und-vorstellung-7', cat: 'Wille und Vorstellung (2018)', range: [2018] },
    { filename: 'wille-und-vorstellung-2018/wille-und-vorstellung-8.jpg.webp', title: 'wille-und-vorstellung-8', cat: 'Wille und Vorstellung (2018)', range: [2018] },
    { filename: 'wille-und-vorstellung-2018/wille-und-vorstellung-9.jpg.webp', title: 'wille-und-vorstellung-9', cat: 'Wille und Vorstellung (2018)', range: [2018] },
];
